/** @format */

.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  z-index: 10;
  overflow: hidden;
  background-color: #fff;
  padding: 10px;
  border: 2px solid #f1d178;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.error-msg {
  padding: 1rem;
}
.error-msg {
  color: red;
  font-size: 1.25rem;
}
.modal-close {
  padding: 1rem;
  display: flex;
  justify-content: end;
}
.modal-close button {
  background: steelblue;
  color: #fff;
}
