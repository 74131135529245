/** @format */

.storiesSlides {
  .slick-list {
    .slick-track {
      margin-left: 0px !important;
      .slick-slide {
        width: 65px !important;
      }
    }
  }
  .slick-prev {
    display: none !important;
  }
  .slick-next {
    color: black;
    box-shadow: 0px 1px 3px 0px gray;
    border-radius: 50%;
    right: 1px !important;
    width: 25px !important;
    height: 25px !important;
    background-color: white !important;
  }
}
