/** @format */

.slick-prev {
  color: black;
  width: 25px !important;
  height: 25px !important;
  box-shadow: 0px 1px 3px 0px gray;
  border-radius: 50%;
  background-color: red !important;
}
.slick-next {
  color: black;
  box-shadow: 0px 1px 3px 0px gray;
  border-radius: 50%;
  right: 15px !important;
  width: 25px !important;
  height: 25px !important;
  background-color: white !important;
}
.slick-dots {
  bottom: 8px;
  color: white !important;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: white;
}
.slick-dots li button:before {
  color: white;
}
@media only screen and (max-width: 600px) {
  .slick-prev {
    display: none !important;
  }
  .slick-next {
    display: none !important;
  }
}
