/** @format */

.cardsSlides {
  .slick-list {
    .slick-track {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: '20px';
      .slick-slide {
        width: 100% !important;
      }
    }
  }
  .slick-prev {
    display: none !important;
  }
  .slick-next {
    color: black;
    box-shadow: 0px 1px 3px 0px gray;
    border-radius: 50%;
    right: 1px !important;
    width: 25px !important;
    height: 25px !important;
    background-color: white !important;
  }
}
.sc-guDLey {
  overflow: unset !important;
}
